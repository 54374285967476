import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ReactLoading from "react-loading";
import moment from "moment";

import {
    formatNumber,
    ClaimReward
} from "../helper/calculation";
import { toastAlert } from "../helper/toastAlert";
import config from "../lib/config";
import { setBalances, getuserhistory } from "../actions/user";
import { useSelector } from "react-redux";

export default function HistoryList(props) {

    const walletConnection = useSelector((state) => state.walletConnection);
    const loadHistory = useSelector((state) => state.loadHistory);

    const [buyList, setbuyList] = useState([]);
    const [info, setinfo] = useState({
        amount: 0,
        lockedAmount: 0,
        claimedAmount: 0,
        claimableAmount: 0,
        nextDate: null
    });
    const [isLoad, setisLoad] = useState(false);
    const [txid, settxid] = useState("");
    const [isWithdraw, setisWithdraw] = useState(false);

    useEffect(() => {
        getHistory();
    }, [walletConnection, loadHistory]);

    async function getHistory() {
        setisLoad(true);
        if (walletConnection && walletConnection.address && walletConnection.address !== "") {
            let { result } = await getuserhistory({ address: walletConnection.address });
            setbuyList(result);
        }
        setisLoad(false);

    }

    const columns = [
        {
            name: "S.No",
            selector: (row, index) => {
                return index + 1;
            }
        },
        {
            name: "Purchase Date",
            width: "250PX",
            selector: (row, index) => {
                let date = new Date(row.createdAt);
                return moment(date).format("MMMM,Do YYYY");
            }
        },
        {
            name: "Buy Amount",
            width: "200PX",
            selector: (row, index) => {
                return (<div>
                    <img src={require("../assets/images/usdt.png")} className="me-2 img-fluid" width={"32px"} height={"32px"}></img>
                    <span>{formatNumber(row.amount / 10 ** 6, 5)} USDT</span>
                </div>)
            }
        },
        {
            name: "Received Amount",
            width: "230PX",
            selector: (row, index) => {
                return (<div>
                    <img src={require("../assets/images/kong.png")} className="me-2 img-fluid" width={"32px"} height={"32px"}></img>
                    <span>{formatNumber(row.rewardamount / 10 ** 18, 5)} $KONG</span>
                </div>)
            }
        },
        {
            name: "Hash",
            width: "230PX",
            selector: (row, index) => {
                return (
                    <a style={{ color: "black" }} target="_blank" className="" href={config.txLink + "tx/" + row.hash}
                    >
                        <i className="fa fa-eye" aria-hidden="true"></i>
                    </a>
                )

            }
        },

    ];

    async function ClaimToken() {
        try {
            setisWithdraw(true)
            var { hash, status, error } = await ClaimReward(info.date);
            if (status) {
                toastAlert("success", "Congrats, Successfully withdraw...!", "withdraw");
                window.$("#withdraw_detail_modal").modal("hide");
                setTimeout(function () {
                    window.$("#withdraw_success_modal").modal("show");
                }, 300);

                settxid(hash)
                getHistory()
            } else {
                toastAlert("error", error, 'withdraw');

            }
            setisWithdraw(false)
        } catch (err) {
            setisWithdraw(false)
        }

    }

    return (
        <section className="table_sec">
            <div className="container">
                <div className="table_size">
                    <h2 className="h2tag">Purchase History</h2>
                    {isLoad ?
                        <div className="justify-content-center mt-4">
                            <ReactLoading type={"bars"} color="black" />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={buyList}
                            noHeader
                            pagination={true}
                            paginationPerPage="10"
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                    }
                </div>
            </div>

            <div
                className="modal fade primary_modal"
                id="withdraw_detail_modal"
                tabIndex={-1}
                aria-labelledby="withdraw_detail_modalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="withdraw_detail_modalLabel">
                                Withdraw
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="buytoken_confirm_body">
                                <div>
                                    <label>Your Claimable Token</label>
                                    <label>{formatNumber(info.claimableAmount, 5)} $KONG</label>
                                </div>
                                <div className="justify-content-center mt-4">
                                    <button
                                        disabled={(info && info.claimableAmount && parseFloat(info.claimableAmount) > 0 && !isWithdraw) ? false : true}
                                        className="primary_btn"
                                        onClick={() => ClaimToken()}
                                    >
                                        Confirm Withdraw{isWithdraw && <i className="fas fa-spinner fa-spin"></i>}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    );
}
