import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
    getAllowanceBalance,
    approveToken,
    PurchaseToken
} from "../helper/calculation";
import { toastAlert } from "../helper/toastAlert";
import config from "../lib/config";
import { setBalances } from "../actions/user";
import { setIsLoadHistory } from "../reducers/Actions";

import { useSelector } from "react-redux";

export default function ConfirmBuy(props) {

    var { loadData } = props;

    const dispatch = useDispatch();
    const userBalances = useSelector((state) => state.balances);
    const walletConnection = useSelector((state) => state.walletConnection);
    const loadHistory = useSelector((state) => state.loadHistory);

    const [isLoad, setisLoad] = useState(false);
    const [txid, settxid] = useState("");
    const [amount, setamount] = useState(0);
    const [receivedAmount, setreceivedAmount] = useState(0);
    const [showApprove, setshowApprove] = useState(false);
    const [showBuy, setshowBuy] = useState(false);


    useEffect(() => {
        setamount(props.amount);
        setreceivedAmount(props.receivedAmount);
        if (props.amount && parseFloat(props.amount) > 0) {
            loadBalances(props.amount);
        }
    }, [props.amount]);


    async function loadBalances(amt) {
        var userallowance = parseFloat(userBalances.tokenallowance);
        if (userBalances.isLoad == "no") {
            try {
                var { allowance } = await getAllowanceBalance();
                userallowance = parseFloat(allowance);

            } catch (err) { }

        }
        setshowApprove(false);
        setshowApprove(false);
        if (userallowance < parseFloat(amt)) {
            setshowApprove(true);
        } else {
            setshowBuy(true)
        }
    }

    async function submitBuy() {

        try {
            setisLoad(true);
            let { status, error, address, hash } = await PurchaseToken(amount);
            if (error) {
                toastAlert("error", error);
            } else if (status) {
                window.$("#buytoken_confirm_modal").modal('hide');

                setTimeout(function () {

                    window.$("#withdraw_success_modal").modal("show");
                }, 300);
                settxid(hash);

                loadData()
                setTimeout(function () {
                    dispatch(
                        setIsLoadHistory({
                            status: !loadHistory.status
                        })
                    );
                }, 1000)

            }
            setisLoad(false)
        } catch (err) {
            setisLoad(false);
            toastAlert("error", "Oops!, Please try again later");
        }
    }

    async function submitApprove() {

        try {
            setisLoad(true)
            var tokenbalance = userBalances.tokenbalance;
            var userallowance = userBalances.tokenallowance;

            if (userBalances.isLoad == "no") {
                try {
                    var { balanceOf, allowance, bnbBal, kongbalanceOf, isLoad } = await getAllowanceBalance();
                    tokenbalance = balanceOf;
                    userallowance = allowance;
                    dispatch(
                        setBalances({
                            tokenbalance: balanceOf,
                            tokenallowance: allowance,
                            balance: bnbBal,
                            kongbalance: kongbalanceOf,
                            isLoad: isLoad
                        })
                    );
                } catch (err) { }

            }

            console.log("submitApprove")
            if (parseFloat(tokenbalance) < parseFloat(amount)) {
                toastAlert("error", "Sorry Insufficient BUSD balance");
                return;
            }
            var isApprove = false;
            if (parseFloat(userallowance) < parseFloat(amount)) {
                var { isAllowed, approvalAmt, error } = await approveToken();
                if (error && error !== "") {
                    toastAlert("error", error, 'error1');
                    return;
                } else if (isAllowed && parseFloat(approvalAmt) >= parseFloat(amount)) {
                    toastAlert("success", "Successfully approved", "approve");
                    isApprove = true;
                    setshowApprove(false);
                    setshowBuy(true);
                } else if (parseFloat(approvalAmt) < parseFloat(amount)) {
                    toastAlert("error", "Sorry!, Insufficient approved funds", 'error1');
                }

            }
            setisLoad(false)
            return {
                isApprove
            }

        } catch (err) {
            setisLoad(false)
            return {
                isApprove: false
            }
        }
    }


    return (
        <>
            <div
                className="modal fade primary_modal"
                id="buytoken_confirm_modal"
                tabIndex={-1}
                aria-labelledby="buytoken_confirm_ModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="buytoken_confirm_ModalLabel">
                                Buy Token
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="buytoken_confirm_body">
                                <div>
                                    <label>You Pay</label>
                                    <label>{amount} USDT</label>
                                </div>
                                <div>
                                    <label>You Will Get</label>
                                    <label>{receivedAmount} $KONG</label>
                                </div>
                                <div className="justify-content-center mt-4">
                                    {showApprove ?
                                        <button
                                            onClick={() => submitApprove()}
                                            disabled={isLoad}
                                            className="primary_btn">
                                            Approve{isLoad && <i className="fas fa-spinner fa-spin"></i>}
                                        </button>
                                        :
                                        <button
                                            onClick={() => submitBuy()}
                                            disabled={isLoad}
                                            className="primary_btn">
                                            Buy Token{isLoad && <i className="fas fa-spinner fa-spin"></i>}
                                        </button>
                                    }
                                </div>
                                {isLoad && <div className="justify-content-center mt-4">
                                    Your transaction is processing..!
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade primary_modal"
                id="withdraw_success_modal"
                tabIndex={-1}
                aria-labelledby="withdraw_success_modal_modalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="withdraw_success_modalLabel">
                                Successfully Purchased $KONG Token
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="buytoken_confirm_body buytoken_confirm_body_chg">
                                <div className="justify-content-center mt-4">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>
                                    <a href={config.txLink + "tx/" + txid} target="_blank" className="d-block mt-3"> <i className="fa fa-eye" aria-hidden="true">
                                    </i> View Transaction </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
