import {
    BALANCES
} from '../constant';

const initialState = {
    tokenallowance: 0,
    tokenbalance: 0,
    balance: 0,
    isLoad: "no",
    kongbalance: 0
};

const balances = (state = initialState, action) => {
    switch (action.type) {
        case BALANCES:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default balances;