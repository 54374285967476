import {
    ISLOADHISTORY
} from '../constant';

const initialState = {
    status: false
};

const loadHistory = (state = initialState, action) => {
    switch (action.type) {
        case ISLOADHISTORY:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default loadHistory;