import axios from "axios";

// import lib
import config from "../lib/config";

//LoginAction
import { BALANCES } from "../constant/index"

export const userHistoryupdate = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/save-buy-token`,
      data,
    });
    return {
      loading: true,
      result: respData.data,
      error: (respData && respData.data && respData.data.errors) ? respData.data.errors : "",
    };
  } catch (err) {
    return {
      loading: false,
      error: err
    };
  }
};


export const getuserhistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/user-details`,
      data,
    });
    return {
      loading: true,
      result: (respData && respData.data && respData.data.result) ? respData.data.result : [],
      error: (respData && respData.data && respData.data.errors) ? respData.data.errors : "",
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const getFaqList = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/faq-list`,
      data,
    });
    return {
      loading: true,
      result: (respData && respData.data && respData.data.result) ? respData.data.result : [],
      error: (respData && respData.data && respData.data.errors) ? respData.data.errors : "",
    };
  } catch (err) {
    return {
      loading: false,
      error: err,
    };
  }
};

export const setBalances = details => {
  return {
    type: BALANCES,
    payload: details
  };
};