let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
let Envname = "prodction";

if (Envname === "prodction") {
  console.log("Set demo Config");
  const API_URL = "https://api.kongmemecoin.io";
  key = {
    baseUrl: `${API_URL}`,
    ICOcontractAddress: "0x6426Ba37f16E2663cE44E48D638259E91aEa46Cc",
    BUSDcontractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    KongTokenaddress: "0x014ec6A5a8B1b7aBF7Fb150EE61d6516C21f144A",
    toasterOption: toasterOption,
    NetworkId: 1,
    rpcUrl: "https://mainnet.infura.io/v3/003dd3bbc4d947eb9e9c1870a5958806",
    txLink: "https://etherscan.io/",
    infuraId: "d5b89bd22ca242798dd9feb0ab031ccf",//ethereum
  };
} else if (Envname === "demo") {
  console.log("Set demo Config");
  const API_URL = "https://nicolasido.wearedev.team";
  key = {
    baseUrl: `${API_URL}`,
    frontend: "https://nicholas-ido.pages.dev",
    ICOcontractAddress: "0x9F2D97b47967E15aCa0428332532E35116bdC503",//local
    BUSDcontractAddress: "0xC99EaD289f767bc2eF0Ae57A3C1323cc50e3e13f",
    KongTokenaddress: "0x3bd2732C964BA89e1AE4f0A8319E36b58b6A4f6F",
    NetworkId: 11155111,
    rpcUrl: "https://sepolia.infura.io/v3/d5b89bd22ca242798dd9feb0ab031ccf",
    txLink: "https://sepolia.etherscan.io/",
    infuraId: "d5b89bd22ca242798dd9feb0ab031ccf",//ethereum
  };
} else {
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    ICOcontractAddress: "0x6426Ba37f16E2663cE44E48D638259E91aEa46Cc",
    BUSDcontractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    KongTokenaddress: "0x014ec6A5a8B1b7aBF7Fb150EE61d6516C21f144A",
    toasterOption: toasterOption,
    NetworkId: 1,
    rpcUrl: "https://mainnet.infura.io/v3/003dd3bbc4d947eb9e9c1870a5958806",
    txLink: "https://etherscan.io/",
    infuraId: "d5b89bd22ca242798dd9feb0ab031ccf",//ethereum
  };
}

export default key;
