import { combineReducers } from "redux";

import web3Reucer from '../reducers/webReducer';
import walletConnection from '../reducers/walletConnection';
import balances from '../reducers/balances';
import loadHistory from '../reducers/loadHistory';

export default combineReducers({
    web3Reucer,
    walletConnection,
    balances,
    loadHistory
});