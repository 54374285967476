import Web3 from "web3";
import { Multicall } from "ethereum-multicall";
import moment from "moment";
import IcoContractABI from "../ABI/ICOcontract.json";
import BEP20ABI from "../ABI/BEP20ABI.json";

import config from "../lib/config";
import { connection } from "../helper/connection";

import { userHistoryupdate } from "../actions/user";

var web3Default = new Web3(config.rpcUrl);


export async function tokenBusdPrice() {
    try {
        var Contract = new web3Default.eth.Contract(IcoContractABI, config.ICOcontractAddress);
        let price = await Contract.methods.tokenbusdprice().call();
        return {
            price
        }
    } catch (err) {
        return {
            price: 0
        }
    }
}

export async function gethardcap() {

    try {

        const multicall = new Multicall({
            web3Instance: web3Default
        });

        var Contract = [
            {
                reference: "hardcapdetail",
                contractAddress: config.ICOcontractAddress,
                abi: IcoContractABI,
                calls: [
                    {
                        reference: "hardcap",
                        methodName: "hardcap",
                        methodParameters: [],
                    },
                    {
                        reference: "totalhardcap",
                        methodName: "totalhardcap",
                        methodParameters: [],
                    },
                ]
            }
        ];

        const results = await multicall.call(Contract);
        var hardcap = await getFormatMulticall(results, "hardcapdetail", 0);
        hardcap = parseInt(hardcap.hex) / 10 ** 6;

        var totalhardcap = await getFormatMulticall(results, "hardcapdetail", 1);
        totalhardcap = parseInt(totalhardcap.hex) / 10 ** 6;

        var availablePurchase = totalhardcap - hardcap;

        return {
            availablePurchase
        }
    } catch (err) {
        console.log(err, 'errerrerrerr')
        return {
            availablePurchase: 0
        }
    }

}


export async function calculatePrice(tokenPrice, _amount) {
    try {
        var tokenDeci = 6;
        var perToken = tokenPrice * _amount;
        perToken = perToken / 10 ** tokenDeci;
        perToken = perToken / 10 ** 18;
        return {
            perToken
        }
    } catch (err) {
        return {
            perToken: 0
        }
    }
}

export async function calculateKongPrice(tokenPrice, _amount) {
    try {
        var tokenDeci = 6;
        var perToken = tokenPrice * _amount;
        perToken = perToken / 10 ** tokenDeci;
        perToken = perToken / 10 ** 18;
        // perToken = 1 / perToken;
        return {
            perToken
        }
    } catch (err) {
        return {
            perToken: 0
        }
    }
}

export async function getAllowanceBalance() {

    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3
        });
        var address = get.address;
        var Contract = [
            {
                reference: "balanceOf",
                contractAddress: config.BUSDcontractAddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [address],
                    },
                    {
                        reference: "allowance",
                        methodName: "allowance",
                        methodParameters: [address, config.ICOcontractAddress],
                    },
                ]
            },
            {
                reference: "balanceOf1",
                contractAddress: config.KongTokenaddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [address],
                    }
                ]
            }
        ];

        const results = await multicall.call(Contract);
        var balanceOf = await getFormatMulticall(results, "balanceOf", 0);
        balanceOf = parseInt(balanceOf.hex) / 10 ** 6;
        var allowance = await getFormatMulticall(results, "balanceOf", 1);
        allowance = parseInt(allowance.hex) / 10 ** 6;

        var getBalance = await web3.eth.getBalance(address);
        getBalance = parseFloat(getBalance);
        getBalance = getBalance / 10 ** 18;

        var kongbalanceOf = await getFormatMulticall(results, "balanceOf1", 0);
        kongbalanceOf = parseInt(kongbalanceOf.hex) / 10 ** 18;

        return {
            balanceOf,
            allowance,
            bnbBal: getBalance,
            kongbalanceOf,
            isLoad: "yes"
        }
    } catch (err) {
        return {
            balanceOf: 0,
            allowance: 0,
            bnbBal: 0,
            kongbalanceOf: 0,
            isLoad: "no"
        }
    }

}

export async function approveToken() {

    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(BEP20ABI, config.BUSDcontractAddress);
        var approveAmt = 100000 * (10 ** 6);
        approveAmt = await convert(approveAmt);

        var getBalance = await web3.eth.getBalance(address);
        getBalance = parseFloat(getBalance);
        getBalance = getBalance / 10 ** 6;

        var estimateGas = await Contract.methods.approve(
            config.ICOcontractAddress,
            approveAmt.toString()
        ).estimateGas({ from: address });

        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > getBalance) {
            return {
                isAllowed: false,
                approvalAmt: 0,
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} ETH) in your wallet.`
            }
        }


        var result = await Contract.methods.approve(
            config.ICOcontractAddress,
            approveAmt.toString()
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
            && result.events.Approval.returnValues && result.events.Approval.returnValues
            && result.events.Approval.returnValues.value) ?
            parseFloat(result.events.Approval.returnValues.value) : 0
        approvalAmt = approvalAmt / 10 ** 6;

        return {
            approvalAmt: approvalAmt,
            isAllowed: (result && result.status) ? result.status : false,
            error: ""
        }
    } catch (err) {
        console.log(err, 'errerrerr')
        return {
            approvalAmt: 0,
            isAllowed: false,
            error: ""
        }
    }

}

export async function PurchaseToken(amount) {
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(IcoContractABI, config.ICOcontractAddress);
        var amount = amount * (10 ** 6);
        amount = await convert(amount);

        console.log(amount.toString(), config.ICOcontractAddress)

        var estimateGas = await Contract.methods.deposit(
            amount.toString()
        ).estimateGas({ from: address });

        estimateGas = estimateGas + 100000;

        var getBalance = await web3.eth.getBalance(address);
        getBalance = parseFloat(getBalance);
        getBalance = getBalance / 10 ** 18;

        if (parseFloat(estimateGas) / 10 ** 8 > getBalance || getBalance == 0) {
            return {
                status: false,
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} ETH) in your wallet.`
            }
        }

        var result = await Contract.methods.deposit(
            amount.toString()
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        var hash = (result && result.transactionHash) ? result.transactionHash : "";

        var getEvtVal = await Contract.getPastEvents("buyEvent", { fromBlock: parseFloat(result.blockNumber), toBlock: parseFloat(result.blockNumber) });
        var events = (getEvtVal && getEvtVal[0] && getEvtVal[0].returnValues) ? getEvtVal[0].returnValues : {};

        let data = {
            amount: (events && events.amount) ? events.amount : 0,
            rewardamount: (events && events.reward) ? events.reward : 0,
            address,
            hash
        }
        userHistoryupdate(data);

        return {
            status: (result && result.status) ? result.status : false,
            error: "",
            address,
            hash,
        }
    } catch (err) {
        console.log(err, 'errerrerr')
        var error = (err && err.message) ? err.message : err;
        var pos = error.search("insufficient");
        var pos1 = error.search("User denied transaction signature")
        var errMsg = "Please try again later";
        if (pos >= 0) {
            errMsg = "Please make sure you have gas fee in your wallet."
        } else if (pos1 >= 0) {
            errMsg = "Cancelled"
        }
        return {
            status: false,
            error: errMsg,
            address: "",
            hash: (result && result.transactionHash) ? result.transactionHash : "",
        }
    }

}

export async function getBuyList() {
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3
        });
        var buyHistory = [];
        var nextDate = null;
        if (address) {
            var Contract = new web3.eth.Contract(IcoContractABI, config.ICOcontractAddress);
            let dates = await Contract.methods.viewBuyDates(address).call();

            if (dates && dates.length > 0) {
                var getInfo = [];
                for (let d = 0; d < dates.length; d++) {
                    getInfo.push({
                        reference: "getbuy-" + d,
                        contractAddress: config.ICOcontractAddress,
                        abi: IcoContractABI,
                        calls: [
                            {
                                reference: "buyInfo",
                                methodName: "buyInfo",
                                methodParameters: [dates[d].toString(), address],
                            },
                            {
                                reference: "viewUserClaimAmount",
                                methodName: "viewUserClaimAmount",
                                methodParameters: [address, dates[d].toString()],
                            },
                            {
                                reference: "getCurrentDate",
                                methodName: "getCurrentDate",
                                methodParameters: [],
                            },

                        ]
                    });
                }
                const results = await multicall.call(getInfo);

                for (var r1 = 0; r1 < dates.length; r1++) {
                    var info = await getFormatMulticall1(results, "getbuy-" + r1, 0);
                    var date = (info && info[0] && info[0].hex) ? parseInt(info[0].hex) : 0;

                    var amount = (info && info[1] && info[1].hex) ? parseInt(info[1].hex) : 0;
                    amount = amount / 10 ** 6;

                    var lockedAmount = (info && info[2] && info[2].hex) ? parseInt(info[2].hex) : 0;
                    lockedAmount = lockedAmount / 10 ** 18;

                    var claimedAmount = (info && info[3] && info[3].hex) ? parseInt(info[3].hex) : 0;
                    claimedAmount = claimedAmount / 10 ** 18;

                    var claimableAmount = await getFormatMulticall(results, "getbuy-" + r1, 1);
                    claimableAmount = (claimableAmount && claimableAmount.hex) ? parseInt(claimableAmount.hex) : 0;
                    claimableAmount = claimableAmount / 10 ** 18;

                    var getCurrentDate = await getFormatMulticall(results, "getbuy-" + r1, 2);
                    var CurrentDate = (getCurrentDate && getCurrentDate.hex) ? parseInt(getCurrentDate.hex) * 1000 : 0;

                    var buyDate = date * 1000;
                    nextDate = getClaimedDate(buyDate, CurrentDate)

                    buyHistory.push({
                        date,
                        amount,
                        lockedAmount,
                        claimedAmount,
                        claimableAmount,
                        nextDate: (nextDate) ? moment(nextDate).format("MMMM,Do YYYY") : ""
                    });
                }

            }
        }

        return {
            list: buyHistory
        }
    } catch (err) {
        console.log(err, "getBuyList err")
        return {
            list: []
        }
    }
}


export async function getHistoryInfo(info) {

    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var nextDate = null;
        if (address) {

            const multicall = new Multicall({
                web3Instance: web3
            });
            var address = get.address;
            var Contract = [
                {
                    reference: "icoInfo",
                    contractAddress: config.ICOcontractAddress,
                    abi: IcoContractABI,
                    calls: [
                        {
                            reference: "viewUserClaimAmount",
                            methodName: "viewUserClaimAmount",
                            methodParameters: [address, info.date.toString()],
                        },
                        {
                            reference: "getCurrentDate",
                            methodName: "getCurrentDate",
                            methodParameters: [],
                        },
                    ]
                }
            ];
            const results = await multicall.call(Contract);
            var claimableAmount = await getFormatMulticall(results, "icoInfo", 0);
            claimableAmount = (claimableAmount && claimableAmount.hex) ? parseInt(claimableAmount.hex) : 0

            var getCurrentDate = await getFormatMulticall(results, "icoInfo", 1);

            var CurrentDate = (getCurrentDate && getCurrentDate.hex) ? parseInt(getCurrentDate.hex) * 1000 : 0;
            var buyDate = (info && info.date) ? parseInt(info.date) * 1000 : 0;
            nextDate = getClaimedDate(buyDate, CurrentDate)
        }
        let amount = (info && info.amount) ? info.amount / 10 ** 18 : 0;
        let lockedAmount = (info && info.lockedAmount) ? info.lockedAmount / 10 ** 18 : 0;
        let claimedAmount = (info && info.claimedAmount) ? info.claimedAmount / 10 ** 18 : 0;
        claimableAmount = claimableAmount / 10 ** 18;

        return {
            amount,
            lockedAmount,
            claimedAmount,
            claimableAmount,
            nextDate: (nextDate) ? moment(nextDate).format("MMMM,Do YYYY") : ""
        }
    } catch (err) {
        console.log(err, "getBuyList err")
        return {
            amount: 0,
            lockedAmount: 0,
            claimedAmount: 0,
            claimableAmount: 0,
            nextDate: null
        }
    }
}

function getClaimedDate(buyDate, CurrentDate) {

    var date = new Date(buyDate);
    //var result = date.addMonths(6);
    console.log(date, 'datedatedate')
    var newDate = date.setDate(date.getDate() + 150);
    console.log(newDate, 'datedatedate')

    var list = [newDate];
    var addDate = new Date(newDate);
    console.log(addDate, 'addDateaddDateaddDate')
    var nextClaimDate = null;
    for (var d = 1; d <= 31; d++) {
        addDate = addDate.setDate(addDate.getDate() + 30);
        addDate = new Date(addDate);
        //var date1 = moment(addDate).format("MMMM,Do YYYY")
        list.push(addDate)
        if (addDate > new Date(CurrentDate)) {
            nextClaimDate = addDate;
            break;
        }

    }
    console.log(nextClaimDate, 'nextClaimDate')
    return nextClaimDate;

}


export function formatNumber(num, defaultFixed) {
    try {
        if (defaultFixed && parseInt(defaultFixed) > 0) {
            defaultFixed = parseInt(defaultFixed);
        } else {
            defaultFixed = 5;
        }

        var numval = num.toString();
        numval = convert(numval);
        var chkDeci = numval.split(".");
        var returnNum = num;
        if (chkDeci.length == 2) {
            if (defaultFixed < chkDeci[1].length) {
                returnNum = toFixedWithoutRound(numval, defaultFixed);
            } else {
                var fix = chkDeci[1].length;
                returnNum = toFixedWithoutRound(numval, fix);
            }
        }
        returnNum = convert(returnNum);
        return returnNum;
    } catch (err) {
        return 0;
    }

}

export function toFixedWithoutRound(amount, dec = 2) {

    try {
        const calcDec = Math.pow(10, dec);
        var withoutFixed = Math.trunc(amount * calcDec) / calcDec;
        withoutFixed = isNumberCheck(withoutFixed);
        return withoutFixed;

    } catch (err) {
        return 0;
    }

}

export function isNumberCheck(amount) {
    var numberVal = amount;
    var convertamt = (isFinite(numberVal) && numberVal > 0 && numberVal !== "Infinity") ? numberVal : 0;
    return convertamt;
}


export function halfAddrShow(addr) {
    if (addr) {
        return addr.substring(0, 6) + "...." + addr.slice(addr.length - 3);
    } else {
        return "";
    }
};

export async function ClaimReward(time) {
    try {
        console.log(time, 'amountamountamount')
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(IcoContractABI, config.ICOcontractAddress);

        var estimateGas = await Contract.methods.claimReward(
            time.toString()
        ).estimateGas({ from: address });

        estimateGas = estimateGas + 100000;

        var getBalance = await web3.eth.getBalance(address);
        getBalance = parseFloat(getBalance);
        getBalance = getBalance / 10 ** 18;

        if (parseFloat(estimateGas) / 10 ** 8 > getBalance) {
            return {
                status: false,
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }

        var result = await Contract.methods.claimReward(
            time.toString()
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        var hash = (result && result.transactionHash) ? result.transactionHash : "";

        // var getEvtVal = await Contract.getPastEvents("claimEvent", { fromBlock: parseFloat(result.blockNumber), toBlock: parseFloat(result.blockNumber) });
        //var events = (getEvtVal && getEvtVal[0] && getEvtVal[0].returnValues) ? getEvtVal[0].returnValues : {};


        return {
            status: (result && result.status) ? result.status : false,
            error: "",
            address,
            hash,
        }
    } catch (err) {
        console.log(err, 'errerrerr')
        var err = (err && err.message) ? err.message : err;
        var pos = err.search("User denied transaction signature");
        var errMsg = "Please try again later"
        if (pos > 0) {
            errMsg = "Cancelled..!"
        }


        return {
            status: false,
            error: errMsg,
            address: "",
            hash: "",
        }
    }

}

export async function getFormatMulticall(results, name, pos) {

    try {
        var returnVal = (results && results.results && results.results[name]
            && results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext[pos] &&
            results.results[name].callsReturnContext[pos].returnValues &&
            results.results[name].callsReturnContext[pos].returnValues[0]) ?
            results.results[name].callsReturnContext[pos].returnValues[0] : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

export async function getFormatMulticall1(results, name, pos) {

    try {
        var returnVal = (results && results.results && results.results[name]
            && results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext &&
            results.results[name].callsReturnContext[pos] &&
            results.results[name].callsReturnContext[pos].returnValues &&
            results.results[name].callsReturnContext[pos].returnValues) ?
            results.results[name].callsReturnContext[pos].returnValues : "";
        return returnVal;
    } catch (err) {
        return "";
    }
}

//function convert(n) {
export function convert(n) {
    try {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n
            .toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign +
            "0." +
            "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
            lead +
            decimal
            : sign +
            lead +
            (+pow >= decimal.length
                ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
                : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
        return 0;
    }
}

export function numberFloatOnly(value) {
    //eslint-disable-next-line
    const regxFormat = /^[]?\d*(?:[.,]\d*)?$/;
    var result = regxFormat.test(value)
    return result;
}